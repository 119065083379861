// ---------------------------------
// ---------- Google Maps Plugin for jQuery ----------
// ---------------------------------
// Just an attemp to make a plugin for easiser use of the google maps api
// ------------------------

;(function ($, window, document, undefined) {

    var pluginName = 'maps';

    // Create Plugin constructor

    function Plugin(element, options) {

        this.element = element;
        this._name = pluginName;
        this._defaults = $.fn.maps.defaults;
        this.opt = $.extend({}, this._defaults, options);
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            this.googleMap();
        },

        googleMap: function () {
            var infoWindow = new google.maps.InfoWindow();

            /* Create Map */

            var map = new google.maps.Map(this.element, {
                zoom: this.opt.zoom,
                center: {
                    lat: this.opt.center.lat,
                    lng: this.opt.center.lng
                },
                disableDefaultUI: this.opt.controls
            });

            /* Create Marker */

            var marker = new google.maps.Marker({
                position: {
                    lat: this.opt.center.lat,
                    lng: this.opt.center.lng
                },
                map: map,
                clickable: this.opt.marker.clickable,
                title: this.opt.marker.call
            });

            /* Check for Scrollwheel options and set them */

            /* enable map scrolling */

            function enableScrollingWithMouseWheel() {
                map.setOptions({
                    scrollwheel: true
                });
            }

            /* disable map scrolling */

            function disableScrollingWithMouseWheel() {
                map.setOptions({
                    scrollwheel: false
                });
            }

            /* enable map scrolling on Mouseenter */

            if ( this.opt.scrollWheel == 'onMouseEnter' ) {
                google.maps.event.addListener(map, 'mousedown', function () {
                    enableScrollingWithMouseWheel();
                });
            }

            if ( this.opt.scrollWheel == true ) {
                enableScrollingWithMouseWheel();
            }

            if ( this.opt.scrollWheel == false ) {
                disableScrollingWithMouseWheel();
            }

            /* Check if custom style is set */
            
            if ( this.opt.customStyle ) {
                map.setOptions({
                    styles: this.opt.customStyle
                });
            }

            /* Check if custom Marker is set */
            if (this.opt.marker.icon) {
                if (this.opt.marker.icon.path && this.opt.marker.icon.w && this.opt.marker.icon.h) {
                    marker.setOptions({
                        icon: new google.maps.MarkerImage(this.opt.marker.icon.path, null, null, null, new google.maps.Size(this.opt.marker.icon.w, this.opt.marker.icon.h))
                    });
                } else {
                    console.log('Nicht alle Angaben sind gemacht!')
                }
            }
            /* Check if marker is clickable an create infowindow with title */

            if ( this.opt.marker.clickable == true) {
                google.maps.event.addListener(marker, "click", function (evt) {
                    infoWindow.setContent(this.title);
                    infoWindow.open(map, this);
                });
            }

            // google.maps.event.addListener(map, "idle", function(){ google.maps.event.trigger(map, 'resize'); });
        }
    });

    $.fn.maps = function (options) {
        this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            }
        });

        return this;
    };

    $.fn.maps.defaults = {
        center: {
            lat: 51.395609,
            lng: 8.56252900000004
        },
        zoom: 13,
        scrollWheel: true,
        controls: true,
        marker: {
            clickable: false,
            call: 'Field Interactive'
        },
        scrollWheel: true
    }

})(jQuery, window, document);