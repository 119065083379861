function nav() {
    var toggle = jQuery('.hamburger');
    var nav = jQuery('nav');
    var header = jQuery('header');

    toggle.on('click', function() {
        toggle.toggleClass('open');
        header.toggleClass('full');
        nav.toggleClass('open');
        jQuery('body').toggleClass('noOverflow');
    });
}

function desktopNav() {
    jQuery('nav ul.main').children('li').on('mouseenter', function() {
       jQuery('nav').addClass('hover');
       jQuery('body').addClass('noOverflow');
       jQuery('.logo').css('z-index', 0);
       jQuery(this).children('ul').show();
    });

    jQuery('nav ul.main').children('li').on('mouseleave', function() {
        jQuery('nav').removeClass('hover');
        jQuery('body').removeClass('noOverflow');
        jQuery('.logo').css('z-index', 99);
        jQuery(this).children('ul').hide();
    });
}

function cloneTable() {
    var heightHeader = jQuery('header').height();
    var stickyTop = jQuery('#property-name').offset().top - heightHeader - 20; // 20px border

    jQuery(window).on('scroll',function() {
      if (jQuery(window).scrollTop() >= stickyTop && $(window).width() >= 1135) {
        var productName = jQuery('#property-name').clone(true);
        jQuery('.placeholder-table .panel').html(productName);
        jQuery('.placeholder-table').addClass('active');
        jQuery('.request').hide();
        jQuery('.js-wpml-ls-item-toggle').hide();
      } else {
        jQuery('.placeholder-table .panel').html('');
        jQuery('.placeholder-table').removeClass('active');
        jQuery('.request').show();
        jQuery('.js-wpml-ls-item-toggle').show();
      }
    });
}


function subNavTigger() {
    var trigger = jQuery('.trigger');

    trigger.each(function() {
        var self = jQuery(this);

        self.on('click', function(e) {
            e.preventDefault();
            if ( jQuery(this).children().hasClass('fa-angle-down') ) {
                jQuery(this).children().removeClass('fa-angle-down').addClass('fa-angle-up');
                jQuery(this).parent().siblings().slideToggle();
                jQuery(this).parent().parent().siblings().children('ul').slideUp(300);
            } else {
                jQuery(this).children().removeClass('fa-angle-up').addClass('fa-angle-down');
                jQuery(this).parent().siblings().slideToggle();
            }
        });
    });
}

function firstscreen() {
    var slide = jQuery('.firstscreen ul.image');
    slide.slick({
        dots: true,
        infinite: true,
        draggable: false,
        autoplay: false,/* true for built, false for development */
        autoplaySpeed: 8000,
        fade: true,
        cssEase: 'linear',
        prevArrow: '<span class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
        nextArrow: '<span class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
        accessibility: false,
        asNavFor: '.firstscreen ul.teaser',
        responsive: [{
            breakpoint: 1136,
            settings: {
                draggable: true
            }
        }]
    });
}

function firstscreenText() {
    jQuery('.firstscreen ul.teaser').slick({
        dots: false,
        infinite: true,
        draggable: false,
        autoplay: false,/* true for built, false for development */
        autoplaySpeed: 8000,
        fade: true,
        cssEase: 'linear',
        prevArrow: false,
        nextArrow: false,
        accessibility: false,
       asNavFor: '.firstscreen ul.image'
    });
}

function cut() {
    if (jQuery('ul.png li').length >= 2) {
        jQuery('ul.png').slick({
            dots: true,
            infinite: true,
            draggable: false,
            autoplay: false,/* true for built, false for development */
            autoplaySpeed: 8000,
            fade: true,
            cssEase: 'linear',
            accessibility: false,
            prevArrow: '<span class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
            nextArrow: '<span class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>'
        });
    }
}

function matchHeight() {
    var col = jQuery('.col-60');
    var entry = jQuery('ul.entryListSub');
    col.each(function () {
        jQuery(this).find('.col').matchHeight({
            byRow: true,
            property: 'height'
        });
    });

    entry.each(function () {
        jQuery(this).find('li').matchHeight({
            byRow: false,
            property: 'height'
        });
    });
}

function shortLang() {
    jQuery('.wpml-ls-native').each(function() {
        var self = jQuery(this);
        self.text(self.text().substring(0, 2).toUpperCase());
    });
}

function Enquire() {
    enquire
        .register("screen and (min-width: 320px)", {
            match : function() {
                nav();
                subNavTigger();
            }
        })
        .register("screen and (max-width: 1135px)", {
            match : function() {
                productPDF();
            }
        })
        .register("screen and (max-width: 1280px)", {
            unmatch : function () {
                nav();
                subNavTigger();
                desktopNav();
            }
        })
        .register("screen and (min-width: 1280px)", {
            unmatch : function () {
                nav();
                subNavTigger();
                productPDF();
            },
            match : function () {
                desktopNav();
            }
        });
}

function map() {
    jQuery('.map').maps({
        center : {
            lat: 51.4489836,
            lng: 8.862114
        },
        zoom: 9,
        marker: {
            icon: {
                path: '/wp-content/themes/field/assets/img/marker.png',
                w: 51,
                h: 70
            }
        },
        scrollWheel: false,
        customStyle: [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape.natural",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#052366"
                    },
                    {
                        "saturation": "-70"
                    },
                    {
                        "lightness": "85"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "lightness": "-53"
                    },
                    {
                        "weight": "1.00"
                    },
                    {
                        "gamma": "0.98"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "saturation": "-18"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#57677a"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ]
    });
}

function addPainting() {
    var add = jQuery('.addPainting'),
        remove = jQuery('.removePainting');

    add.on('click', function() {
        var count = jQuery('.contactform input[type="file"]').length;
        if (count < 29) {
            var newData = jQuery('.contactform #paint').clone()
                .attr('id', 'paint-' + count)
                .attr('name', 'form[paint][' + count + ']')
                .addClass('clone')
                .val("");
            jQuery('.contactform li.paintings').append(newData);
            remove.addClass('visible');
        }
    });

    remove.on('click', function() {
        jQuery('.contactform li.paintings .clone').last().remove();
        if (jQuery('.contactform li.paintings input[type="file"]').length == 1) {
            remove.removeClass('visible');
        }
    });
}

function initSelect2() {
    jQuery('select.select2').select2();

    jQuery('#comparison select.select2').select2({
        width: '100%',
        placeholder: productsTrans,
        allowClear: true
    });

    $('#comparison select.select2').on('select2:select', function (e) {
        jQuery('#comparison .select-spinner').addClass('loading');
        productComparison()
    });
    $('#comparison select.select2').on('select2:unselect', function (e) {
        jQuery('#comparison .select-spinner').addClass('loading');
        productComparison()
    });
}

function productComparison() {
    var selections = jQuery('#comparison select.select2'),
        productIds = [],
        pdfUri = jQuery('#pdfLink').data('url'),
        form = '#comparison';

    jQuery.each(selections, function(i, product) {
        if (jQuery(product).val()) {
            productIds.push(jQuery(product).val());
        }
    });

    if (productIds.length == 0) {
        jQuery('#comparison .select-spinner').removeClass('loading');

        return false;
    } else if (productIds.length > 3) {
        jQuery(form).find('.warning').html('<span class="alert">' +
            'Nicht mehr als drei Produkte pro Vergleich auswählbar.' +
            '</span>');

        jQuery('#comparison .select-spinner').removeClass('loading');

        return false;
    }

    jQuery(form).find('.warning').html('');
    jQuery('.property .value > div ').html('');

    jQuery.each(productIds, function(i, id) {
        pdfUri += 'products[]=' + id + '&';
    });

    jQuery('#pdfLink').attr('href', pdfUri);

    $.ajax({
        type: $(form).attr('method'),
        url: $(form).attr('action'),
        data: $(form).serialize(),
        success: function(data) {
            var products = jQuery.parseJSON(data);

            jQuery.each(products, function(id, properties) {
                jQuery.each(properties, function(propertyName, property) {
                    var value = formatProperty(property);
                    if (propertyName == 'name' && properties.mitsubishiMaterial) {
                        value += '<span>*</span>';
                    }
                    jQuery("li[id=property-" + propertyName + "]").find('.value > div').append('<div class="propertyVal col-xs-4">' + value + '</div>');
                });
            });
        },
        complete : function(){
            jQuery('#comparison .select-spinner').removeClass('loading');
        }
    });
}

function formatProperty(value) {
    var type = jQuery.type(value);

    if (type === "object") {
        var array = $.map(value, function(val, i) {
            return val;
        });

        value = array.join(', ');

        return value;
    }

    if (type === "null") {
        return "";
    }

    if (type !== "string") {
        return value;
    }

    var matches = value.match(/\^(.*?(?=\s|$))/g);
    if (matches) {
        jQuery.each(matches, function(i, match) {
            value = value.replace(new RegExp('\\' + match, "g"), '<sup>'+ match.replace(/\^/, '') +'</sup>')
        });
    }

    if (parseFloat(value)) {
        value = value.replace(/\./, ",");
    }

    return value;
}

function productPDF() {
    var link = jQuery('#dataSheets a').attr('href');
    jQuery('#dataSheets a').attr('href', link + 'pdf');
    jQuery('#dataSheets a').attr('target', '_blank');
}

function checkTable() {
    var table = jQuery('table tr td');

    table.each(function() {
        var self = jQuery(this),
            str = self.text();

        if (str.indexOf('#') >= 0) {
            self.closest('tr').addClass('blue');
            self.text(self.text().replace(/#/i, ''));
        }
    });
}

function disableRightClick() {
	jQuery( 'img' ).bind( 'contextmenu' , function(e) {
    	return false;
	});
}

function disabled() {
    $('#datenschutz').on('change', function(){
        console.log('checked');
        if ( $(this).prop('checked') ) {
            $('button[type=submit]').attr({
                disabled: false
            });
        } else {
            $('button[type=submit]').attr({
                disabled: true
            });
        }
    });
}

function notice() {
    var body = jQuery('body');
    var header = jQuery('header');
    var notice = jQuery('#notice');

    // check cookie
    if (jQuery(notice).length > 0) {
        if (Cookies.get('Notice')) {
            jQuery(notice).remove();
        } else {
            jQuery(notice).addClass('show');
            jQuery(body).addClass('notice');
            jQuery(header).addClass('notice');
        }
    }

    // set cookie
    jQuery( '.agree' ).click(function() {
        jQuery(notice).remove();
        jQuery(body).removeClass('notice');
        jQuery(header).removeClass('notice');
        Cookies.set('Notice', true, {
            path: '/',
            expires: 365
        });
    });
}

function disableGoogleAnalytics() {
    jQuery('.gaOptOut').click(function() {
        gaOptout();
        alert('Google Analytics wurde deaktiviert!');
        return false;
    });
}

function mapActivate() {
    jQuery('.activate-map').on('click', function () {
        console.log('Activate Map');
        map();
    })
}

jQuery(document).ready(function() {
    // disabled();
    notice();
    Enquire();
    firstscreen();
    cut();
    matchHeight();
    shortLang();
    firstscreenText();
    mapActivate();
    addPainting();
    checkTable();
    disableRightClick();
    disableGoogleAnalytics();
    initSelect2();
    cloneTable();
    // productComparison();
});
